var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-slider", {
    ref: "Slider",
    staticClass: "slider",
    attrs: {
      min: _vm.min,
      max: _vm.max,
      interval: _vm.interval,
      disabled: _vm.disabled,
      tooltip: _vm.tooltip,
      height: 15,
      "dot-size": 30,
      "bg-style": {
        backgroundColor: "#fff",
        border: "1px solid #ddd"
      },
      "process-style": {
        backgroundColor: "#a059b6"
      }
    },
    on: {
      callback: function(val) {
        return _vm.$emit("cb", val)
      }
    },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }