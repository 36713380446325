<template>
  <vue-slider
    ref="Slider"
    class="slider"
    v-model="value"
    :min="min"
    :max="max"
    :interval="interval"
    :disabled="disabled"
    :tooltip="tooltip"
    @callback="val => $emit('cb', val)"
    :height="15"
    :dot-size="30"
    :bg-style="{
      backgroundColor: '#fff',
      border: '1px solid #ddd',
    }"
    :process-style="{
      backgroundColor: '#a059b6',
    }"
  >
  </vue-slider>
</template>

<script>
import vueSlider from 'vue-slider-component'

export default {
  components: {
    vueSlider,
  },
  props: {
    data: {
      type: [String, Number, Array],
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    interval: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: [String, Boolean],
      default: false, // ['hover', 'always', false]
    }
  },
  data() {
    return {
      value: this.data
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.Slider &&
          'refresh' in this.$refs.Slider &&
          typeof this.$refs.Slider.refresh === 'function'
      ) {
        this.$refs.Slider.refresh()
      }
    }, 500)
  }
}
</script>
